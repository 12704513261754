import { Authenticator } from '@aws-amplify/ui-react';

function AppProtectedComponents({ signOut, user }) {
  console.log(user);
  return (
    <> <div class="ProtectedComponents">
          <Authenticator>
            {({ signOut, user }) => (
              <>
                <h1>Hello {user.attributes.nickname}</h1>
                <button onClick={signOut}>Sign out</button>
              </>
            )}
          </Authenticator>
        </div>
    </>
  );
}

export default AppProtectedComponents;