import logo from './logo.svg';
import './App.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import AppProtectedComponents from './AppProtectedComponents';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import ComingSoon from './pages/ComingSoon';


Amplify.configure(awsExports);

function App() {
  return (
    <>
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/">
            <Route path="app" element={<AppProtectedComponents /> } />
            <Route index element={<ComingSoon />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
    </>
  );
}

function ReactDefaultPage() {
  return <header className="App-header">
    <img src={logo} className="App-logo" alt="logo" />
    <p>
      Edit <code>src/App.js</code> and save to reload.
    </p>
    <a
      className="App-link"
      href="https://reactjs.org"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn React
    </a>
  </header>;
}

export default App;




